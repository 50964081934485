.container {
  display: flex;
  position: relative;
}
.button {
  color: var(--dl-color-gray-900);
  cursor: pointer;
  font-size: large;
  background: white;
  font-style: normal;
  transition: all .15s ease-in;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.4;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-blue-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 25px;
  padding-right: var(--dl-space-space-doubleunit);
  letter-spacing: -0.025rem;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-blue-500);
  text-transform: uppercase;
}
.button:hover {
  color: var(--dl-color-blue-500);
  background-color: var(--dl-color-gray-900);
}

@media(max-width: 479px) {
  .button {
    padding: 0.5rem 1rem;
  }
}
