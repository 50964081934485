.Header {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  display: flex;
  z-index: 3;
  position: absolute;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.image {
  top: 0;
  width: 200px;
  height: auto;
  display: none;
  z-index: 2;
  position: absolute;
  object-fit: cover;
}
.Nav {
  flex: 0 0 auto;
  color: var(--dl-color-secondary-600);
  width: 100%;
  height: 70px;
  display: flex;
  padding: 1rem;
  position: relative;
  box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: 30px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(32, 39, 63, 0.83);
}
.image1 {
  width: 35%;
  height: auto;
  position: absolute;
  object-fit: cover;
}
.BurgerButton {
  width: auto;
  height: auto;
  display: none;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.BurgerIcon {
  width: var(--dl-size-size-doubleunit);
  height: auto;
  transition: 0.3s;
  text-decoration: none;
}
.BurgerIcon:hover {
  fill: var(--dl-color-secondary-500);
}
.container {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.MenuLeft {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  line-height: 1.5;
  flex-direction: row;
  justify-content: flex-start;
}
.navlink1 {
  color: var(--dl-color-gray-900);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  letter-spacing: -0.025rem;
}
.navlink1:hover {
  text-shadow: 0px 0px 15px #fff;
  fill: #fff;
}

.Link {
  color: var(--dl-color-gray-900);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  letter-spacing: -0.025rem;
}
.Link:hover {
  text-shadow: 0px 0px 15px #fff;
  fill: #fff;
}

.MenuRight {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.SocialMediaContainer {
  width: auto;
  height: auto;
  display: flex;
  padding: 1rem;
}
.MintButtonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.SocialMediaContainer2 {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-shadow: 2px 2px 4px #00000075;
}
.icon01 {
  fill: var(--dl-color-secondary-100);
  color: var(--dl-color-secondary-100);
  font-size: x-large;
  width: 25px;
  height: 25px;
  margin: 10px;
  transition: 0.3s;
  text-decoration: none;
  text-align: right;
}
.icon01:hover {
  fill: var(--dl-color-secondary-500);
  color: var(--dl-color-secondary-500);
}



.BurgerMenuContainer {
  top: 0px;
  flex: 0 0 auto;
  left: -100%;
  width: 85%;
  display: flex;
  z-index: 3;
  position: fixed;
  max-width: 400px;
  transition: 0.5s;
  align-items: flex-start;
}
.BurgerMenuContainerActive {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 85%;
  display: flex;
  z-index: 3;
  position: fixed;
  max-width: 400px;
  transition: 0.5s;
  align-items: flex-start;
}
.BurgerMenu {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-secondary-700);
}
.LogoContainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.logo {
  width: 40%;
  margin-top: 30px;
  object-fit: cover;
}
.menu {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
}
.ul {
  padding: 0;
}
.li {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navlink3 {
  color: var(--dl-color-gold-shiny);
  transition: 0.3s;
}
.navlink3:hover {
  text-shadow: 0px 0px 8px #fff;
  fill: #fff;
}
.li1 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navlink4 {
  color: var(--dl-color-gold-shiny);
  transition: 0.3s;
}
.navlink4:hover {
  text-shadow: 0px 0px 8px #fff;
}
.li2 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.link5 {
  color: var(--dl-color-gold-shiny);
  transition: 0.3s;
}
.link5:hover {
  text-shadow: 0px 0px 8px #fff;
}
.li3 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.link6 {
  color: var(--dl-color-gold-shiny);
  transition: 0.3s;
}
.link6:hover {
  text-shadow: 0px 0px 8px #fff;
}
.Space {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
}
.XiconContainer {
  top: 0;
  fill: #D9D9D9;
  right: 0;
  width: 50px;
  height: 50px;
  margin: 10px;
  position: absolute;
}
.Xicon {
  top: 0;
  fill: #D9D9D9;
  right: 0;
  width: 30px;
  height: 30px;
  margin: 10px;
  position: absolute;
  text-decoration: none;
}
@media(max-width: 991px) {
  .Nav {
    justify-content: space-between;
  }
  .image1 {
    left: 30%;
  }
  .BurgerButton {
    display: flex;
  }
  .BurgerIcon {
    fill: #D9D9D9;
  }
  .container {
    width: auto;
  }
  .MenuLeft {
    display: none;
  }
}
@media(max-width: 767px) {
  .image {
    display: flex;
  }
  .Nav {
    margin: 0;
    background: none;
    box-shadow: none;
    justify-content: space-between;
  }
  .image1 {
    display: none;
  }
  .BurgerButton {
    display: flex;
  }
  .container {
    width: auto;
  }
  .MenuRight {
    top: 0;
    right: 0;
    position: absolute;
    margin-top: 1rem;
    flex-direction: column;
  }
  .SocialMediaContainer {
    display: none;
  }
  .SocialMediaContainer2 {
    display: flex;
    margin-top: 1rem;
  }
}
@media(max-width: 479px) {
  .Header {
    padding: 0;
  }
  .Nav {
    margin: 0;
    padding-right: 0;
  }
  .MenuRight {
    align-items: flex-end;
    margin-right: 1rem;
  }
  .BurgerMenuContainer {
    top: 0px;
  }
  .BurgerMenu {
    margin-right: 0px;
  }
  .logo {
    width: 80%;
  }
  .li {
    color: var(--dl-color-gold-shiny);
  }
  .li1 {
    color: var(--dl-color-gold-shiny);
  }
  .li2 {
    color: var(--dl-color-gold-shiny);
  }
  .li3 {
    color: var(--dl-color-gold-shiny);
  }
}
