.container {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.container1 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.text {
  color: var(--dl-color-gold-shiny);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: var(--dl-color-primary-700);
}



@media(max-width: 479px) {
  .container1 {
    display: none;
  }
  .text {
    width: 100%;
    font-size: larger;
    text-align: center;
  }
}
