.Phase2 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.container {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.container1 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.container2 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.text {
  color: var(--dl-color-gold-shiny);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: var(--dl-color-primary-700);
}
.VerticalLine {
  left: 50%;
  width: 2px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #D9D9D9;
}
@media(max-width: 479px) {
  .container {
    width: 100%;
  }
  .container2 {
    display: none;
  }
  .text {
    width: 100%;
    font-size: larger;
    text-align: center;
  }
  .VerticalLine {
    display: none;
  }
}
