.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: radial-gradient(circle at center bottom, rgb(206, 236, 255) 0.00%,rgb(78, 124, 170) 8.00%,rgb(4, 21, 32) 22.00%);
}
.webgl{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
}
.starryBackgroundContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.BodyTop {
  width: 100%;
  position: relative;
  /* z-index: 0; */
}
.BodyBody {
  flex: auto;
  width: 100%;
  background-image: url(../../public/playground_assets/BodyBody.svg);
  background-repeat: repeat-y;
  position: relative;

  z-index: 0;
}
.BodyBottom {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at center bottom, rgba(206, 236, 255, 0.8) 0.00%,rgba(78, 124, 170,0.4) 8.00%,rgba(4, 21, 32,0) 22.00%);

}
.loadingScreen{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(20, 26, 44); */
  background-image: radial-gradient(circle, rgb(13, 56, 99) 40.00%,rgb(4, 21, 32));
  opacity: 1;
  transition: 1s;
  overflow: hidden;
}
.loadingIcon{
  align-items: center;
  justify-content: center;
}
.loadingMeter { 
  height: 50px;
  position: relative;
  background: #555;
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.canvasContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.leafContainer{
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display:inline;
}

.Banner {
  width: 100vw;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  justify-content: center;
}

.BodyContainer{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-image: linear-gradient(to right, rgba(24,53,87,1), rgba(6,23,39,1), rgba(24,53,87,1)); */
}

.imageContainer{
  width: 100%;
  height: auto;
  position: relative;
  min-width: 1200px;
  display: block;
}
.bannerImage {
  width: 100%;
  height: auto;
}

.towerLight{
  width: 8.4%;
  top: 21.5%;
  left: 56.2%;
  position: absolute;
  animation-name: towerLightAnim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: line;
}

@keyframes towerLightAnim {
  0%   {transform: scale(1);}
  0%   {transform: rotate(0deg);}
  25%  {transform: scale(1.7);}
  50%  {transform: scale(2);}
  50%  {transform: rotate(-45deg);}
  75%  {transform: scale(1.7);}
  75%  {transform: rotate(-60deg);} 
  100%  {transform: scale(1);}
}

.runeCircle{
  width: 8.4%;
  top: 25%;
  left: 56.15%;
  position: absolute;
  animation-name: runAnim;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes runAnim {
  0%   {transform: rotate(0deg);}
  100%  {transform: rotate(359deg);}
}


.container01 {
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
}
.text {
  color: var(--dl-color-gold-shiny);
  margin: 8%;
  
  font-family: Open Sans;
  /* font-weight: 700; */
  /* line-height: 1.3; */
  text-shadow: 2px 2px black;
  text-transform: none;
  text-decoration: none;
}
.text02 {
  font-size: 3rem;
  font-weight: 300;

}
.text04 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
.text05 {
  font-style: normal;
  font-size: large;
  font-weight: 400;
  text-shadow: 2px 2px 2px black;
  line-height: 1.7;
}
.bodyTextContainer{
  display: inline;
  text-indent: 40px;
  justify-content: center;
  padding: 3rem;
  max-width: 1000px;
  color: var(--dl-color-secondary-100);
}
.read-more-less{
  color: var(--dl-color-gold-gold);
}
/* .grid-container {
  display: grid;
  grid-template-columns: 20% auto;

  padding: 10px;
}

.grid-container > div {
  text-indent: 0;
  text-align: left;
  padding: 20px;
  border-color: #D9D9D9;
  border-width: 2px;
} */


.text02 {
  font-weight: 300;
}
.text04 {
  font-style: normal;
  font-weight: 700;
}
.text05 {
  font-style: normal;
  font-size: large;
  font-weight: 10;
}
.NFTDemo {
  width: 100%;
  height: auto;
  display: flex;
  padding: 50px 0;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
  border-color: var(--dl-color-gold-shiny);
  border-top-width: 5px;
}

.container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
}
.arrow {
  width: var(--dl-size-size-unitandahalf);
  margin: 5px;
  object-fit: cover;
}
.dot {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
}
.dotActive {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
  border-radius: 50%;
  background-image: radial-gradient(circle, rgb(206, 236, 255) 0.00%,rgb(13, 56, 99) 40.00%,rgba(4, 21, 32, 0) 22.00%);
}
.arrow:hover{
  transform: scale(1.1);
}
.dot:hover{
  transform: scale(1.1);
}
/* .image02 {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
}
.image03 {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
}
.image04 {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
}
.image05 {
  width: var(--dl-size-size-unitandahalf);
  margin: 2px;
  object-fit: cover;
} */


.container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section {
  width: 100%;
  height: auto;
  display: flex;
  padding: 50px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}

.container04 {
  width: 100%;
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container06 {
  width: 100%;
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageBorder {
  width: 70%;
  height: auto;
  display: flex;
  padding: 10px;
  transition: 0.3s;
  border-color: var(--dl-color-gold-shiny);
  border-width: 3px;
  justify-content: center;
}
.imageBorder:hover {
  box-shadow: 0px 0px 10px #fff;
  transition: 0.3s;
}
.imageBorder:active {
  transform: scale(1.7);
  transition: 0.3s;
}
.image08 {
  width: 100%;
  height: auto;
}

.titleText {
  color: var(--dl-color-gold-shiny);
  text-align: center;
  margin: 50px;
  margin-bottom: 20px;
}


.image09 {
  width: 100%;
  height: auto;
}


.NFTCraftContainer {
  width: 85%;
  display: flex;
  padding: 2px;
  overflow: hidden;
  max-width: 800px;
  align-items: flex-start;
  border-color: var(--dl-color-gold-shiny);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  border-style: solid;
  margin-top: 30px;
}

.ProfitRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
.ProfitCol {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ProfitItem {
  width: 150px;
  height: 150px;
  margin: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-secondary-500);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.profitText{
  color: var(--dl-color-gold-shiny);
}
.profitImage{
  width: 170px;
}
.profitImage:hover{
  transform: scale(1.2);
  transition: 0.3s;
}

.text09 {
  color: var(--dl-color-gold-shiny);
  margin: 50px;
}
.container08 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.image10 {
  width: 150px;
  margin: 20px;
  object-fit: cover;
  border-color: var(--dl-color-gold-shiny);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-round);
}
.image11 {
  width: 150px;
  margin: 20px;
  object-fit: cover;
  border-color: var(--dl-color-gold-shiny);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-round);
}
.container09 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.image12 {
  width: 150px;
  margin: 20px;
  object-fit: cover;
  border-color: var(--dl-color-gold-shiny);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-round);
}
.image13 {
  width: 150px;
  margin: 20px;
  object-fit: cover;
  border-color: var(--dl-color-gold-shiny);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-round);
}

.Roadmap {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.container10 {
  height: 78px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.Heading {
  font-size: 2.25rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 42px;
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-gold-shiny);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text10 {
  white-space: normal;
  text-decoration: none;
}
.text11 {
  color: var(--dl-color-secondary-100);
  display: flex;
  max-width: 700px;
  text-align: center;
  margin-bottom: 50px;
}
.DotStart {
  width: 30px;
  height: auto;
  object-fit: cover;
}
.Phase1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.container11 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.container13 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.container14 {
  flex: 1;
  height: 2px;
  display: none;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.text14 {
  color: var(--dl-color-gold-shiny);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: var(--dl-color-primary-700);
}
.container15 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.VerticalLine {
  left: 50%;
  width: 2px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  background-color: #D9D9D9;
}
.Phase2 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.container16 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.container17 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.container18 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.text17 {
  color: var(--dl-color-gold-shiny);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: var(--dl-color-primary-700);
}
.VerticalLine1 {
  left: 50%;
  width: 2px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #D9D9D9;
}
.Phase6 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.container19 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.VerticalLine2 {
  flex: 0 0 auto;
  left: 50%;
  width: 2px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  background-color: #D9D9D9;
}
.container21 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.DotEnd {
  width: 30px;
  height: auto;
  object-fit: cover;
}
.Team {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 50px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.text24 {
  color: var(--dl-color-gold-shiny);
  margin: 50px;
}
.container22 {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.image14 {
  width: 100%;
  object-fit: cover;
}
@media(max-width: 991px) {
  .imageBorder{
    width: 90%;
  }
  .container03 {
    height: auto;
  }
  .container04 {
    margin: 20px;
  }
  .container05 {
    width: 80%;
  }
  .container06 {
    margin: 20px 0px;
  }
  .container07 {
    width: 80%;
  }
  .Features {
    max-width: 960px;
  }
  .Roadmap {
    max-width: 960px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .container10 {
    max-width: 80%;
  }
  .Phase1 {
    margin-right: 0px;
  }
  .NFTCraftContainer{
    padding: 20px 0;
    width: 90%;
  }
}
@media(max-width: 767px) {
  .Features {
    max-width: 720px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .Roadmap {
    max-width: 720px;
  }
  
}
@media(max-width: 479px) {
  .container04 {
    margin: 10px;
  }
  .container05 {
    width: 90%;
  }
  .container06 {
    margin: 10px 0px;
  }
  .container07 {
    width: 90%;
  }
  .Features {
    width: 100%;
  }
  .DotStart {
    display: none;
  }
  .Phase1 {
    height: auto;
  }
  .container11 {
    width: 100%;
  }
  .container14 {
    display: none;
  }
  .text14 {
    width: 100%;
    font-size: larger;
    text-align: center;
  }
  .container15 {
    display: none;
  }
  .VerticalLine {
    display: none;
  }
  .container16 {
    width: 100%;
  }
  .container18 {
    display: none;
  }
  .text17 {
    width: 100%;
    font-size: larger;
    text-align: center;
  }
  .VerticalLine1 {
    display: none;
  }
  .container19 {
    display: none;
  }
  .VerticalLine2 {
    display: none;
  }
  .container21 {
    width: 100%;
  }
  .DotEnd {
    display: none;
  }
  .section{
    padding:20px;
  }
  .bodyTextContainer{
    padding:10px;
  }
}






.SlideActive {
  transition:  .5s ease;
  -webkit-transition:  .5s ease;
  -moz-transition:  .5s ease;
  -ms-transition:  .5s ease;
  -o-transition:  .5s ease;
  /* height: 60vh; */
  transform:scale(1.2);
  z-index: 2000;
  position: relative !important;
  padding: 4rem;
  /* margin: 4rem; */
}



.SlideNormal {
  /* height: 50vh; */
  transition:  .5s ease;
  -webkit-transition:  .5s ease;
  -moz-transition:  .5s ease;
  -ms-transition:  .5s ease;
  -o-transition:  .5s ease;
  transform:scale(1);
  padding: 4rem;
}
@media(max-width: 991px) {
  .SlideActive {
    transform:scale(1);
    padding: 1rem;
  }
  
  .SlideNormal {
    height: 100%;
    padding: 1rem;
  }
}

