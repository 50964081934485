.Divider {
  flex: 0 0 auto;
  width: 90%;
  height: 2px;
  display: flex;
  opacity: 0.25;
  margin-top: 0rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 0rem;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(189, 195, 199, 0) 1.00%,rgb(244, 228, 181) 52.00%,rgba(44, 62, 80, 0) 100.00%);
}


