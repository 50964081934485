.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  list-style: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-secondary-700);
}
.container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.logo {
  width: 40%;
  margin-top: 30px;
  object-fit: cover;
}
.menu {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
}
.ul {
  padding: 0;
}
.li {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text {
  color: var(--dl-color-gold-shiny);
}
.li1 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.link {
  color: var(--dl-color-gold-shiny);
}
.li2 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text1 {
  color: var(--dl-color-gold-shiny);
}
.li3 {
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.link1 {
  color: var(--dl-color-gold-shiny);
}
.container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
}


@media(max-width: 991px) {
  .rootClassName1 {
    top: 00px;
    left: 0px;
    right: -87px;
    position: absolute;
  }
}
@media(max-width: 767px) {
  .rootClassName1 {
    top: 0px;
    left: 0px;
    position: absolute;
  }
}
@media(max-width: 479px) {
  .container {
    margin-right: 0px;
  }
  .logo {
    width: 80%;
  }
  .li {
    color: var(--dl-color-gold-shiny);
  }
  .li1 {
    color: var(--dl-color-gold-shiny);
  }
  .li2 {
    color: var(--dl-color-gold-shiny);
  }
  .li3 {
    color: var(--dl-color-gold-shiny);
  }
  .rootClassName1 {
    top: 0px;
    left: 0px;
    position: absolute;
  }
}
