.container {
  flex: 0 0 auto;
  width: auto;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-triplequarterunit);
  padding-right: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.none{
  display: none;
}
.image {
  width: 162px;
  height: 162px;
  object-fit: cover;
  border-radius: 10px;
  border-color: var(--dl-color-gold-shiny);
  border-width: 3px;
}
.text {
  color: var(--dl-color-gold-shiny);
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text1 {
  color: var(--dl-color-gold-shiny);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.link {
  display: contents;
}
.icon {


  fill: var(--dl-color-secondary-100);
  color: var(--dl-color-secondary-100);
  font-size: 21px;
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: 0.3s;
}
.icon:hover {
  fill: var(--dl-color-secondary-500);
  color: var(--dl-color-secondary-500);
  text-shadow: 2px 2px 8px #000;
}






@media(max-width: 767px) {
  .container {
    width: 50%;
  }
}
@media(max-width: 479px) {
  .container {
    width: 100%;
  }
}
