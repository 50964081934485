.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}
.textContainer{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* min-width: 300px; */
}
.title {
  color: var(--dl-color-gold-shiny);
  text-align: center;
}
.bodyTextContainer{
  display: inline;
  justify-content: center;
  text-indent: 0;
  padding: 1rem;
  max-width: 1000px;
  color: var(--dl-color-secondary-100);
}
.read-more-less{
  color: var(--dl-color-gold-gold);
}
.imageContainer {
  width:auto;
  display: flex;
  align-items: center;
  max-width: 90%;

}
.image {
  width: 100px;
  margin: 20px;
  object-fit: cover;
  border-radius: 10px;
  display: block;
}
.image:hover {
  transform: scale(1.5);
  transition: 0.3s;
}

@media(max-width: 991px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .container1 {
    max-width: 100%;
    margin-right: 0px;
    justify-content: center;
  }
  .text {
    text-align: center;
    width: 100%;
  }
  .textContainer{
    margin-left:0;
  }
  .imageContainer {
    flex-wrap: wrap;
  }
}
@media(max-width: 767px){
  
  .imageContainer {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
  .image{
    margin: 10px;
  }
  .bodyTextContainer{
    padding:10px;
  }
}
