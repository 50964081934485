.Footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1320px;
  justify-content: center;
  align-items: center;
}
.container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.link {
  display: contents;
}
.icon {
  fill: var(--dl-color-secondary-100);
  color: var(--dl-color-secondary-100);
  font-size: 24px;
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: 0.3s;
}
.icon:hover {
  fill: var(--dl-color-secondary-500);
  color: var(--dl-color-secondary-500);
  text-shadow: 2px 2px 8px #000;
}
.link1 {
  display: contents;
}
.icon2 {
  fill: var(--dl-color-secondary-100);
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: 0.3s;
}
.icon2:hover {
  fill: var(--dl-color-secondary-500);
  text-shadow: 2px 2px 8px #000;
}
.link2 {
  display: contents;
}
.Icon4 {
  fill: var(--dl-color-secondary-100);
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: 0.3s;
}
.Icon4:hover {
  fill: var(--dl-color-secondary-500);
  text-shadow: 2px 2px 8px #000;
}
.link3 {
  display: contents;
}




