@import 'antd.css';
.App {
  text-align: center;
}

#root {
  margin:0;
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* canvas {
width: 100vw;
height: 100vh;
min-width: 1300px;
} */

.canvasContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modalLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalLoading .ant-modal-content {
  background-color: transparent;
  box-shadow:none;
}

.welcome-wrapper {
  overflow: hidden;
  width: 750px;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  justify-content:center;
  align-items:center;
  text-align: center;
}
.welcome-inner-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  font-family: Cinzel, sans-serif;

}

.welcome-inner-container h2.ant-typography {
  font-size: 4em;
  font-family: Cinzel, sans-serif;
  color: #d9d9d9;
  text-align: center;
}

