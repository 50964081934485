.container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px;
  min-width: 550px;
  box-shadow: 5px 10px 5px 0px #000000;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-secondary-400);
  border-radius: 30px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #171d2b;
}
.container:hover {
  box-shadow: 0px 0px 10px #fff;
  transition: 0.3s;
}
.container01 {
  width: 35%;
  height: auto;
  margin: 20px;
  display: flex;
  position: relative;
  max-height: 100%;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.container02 {
  width: 75%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.text {
  color: var(--dl-color-gold-shiny);
  width: 100%;
  margin-top: 0px;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.container03 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text01 {
  color: var(--dl-color-gold-shiny);
  margin-top: 0px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.container05 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.container06 {
  flex: 0 0 auto;
  width: fit-content;
  height: auto;
  display: flex;
  z-index: 1;
  align-self: flex-start;
  margin-top: 0px 20px ;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px 20px ;
  flex-direction: column;
}
.text02 {
  color: var(--dl-color-gold-shiny);
}
.text03 {
  color: var(--dl-color-gold-shiny);
}
.Text04 {
  color: var(--dl-color-gold-shiny);
}
.Text05 {
  color: var(--dl-color-gold-shiny);
}
.Text06 {
  color: var(--dl-color-gold-shiny);
}
.Text07 {
  color: var(--dl-color-gold-shiny);
}
.Text08 {
  color: var(--dl-color-gold-shiny);
}
.container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  z-index: 1;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.text09 {
  color: var(--dl-color-gold-shiny);
}
.text10 {
  color: var(--dl-color-gold-gold);
}
.Text11 {
  color: var(--dl-color-gold-shiny);
}
.Text12 {
  color: var(--dl-color-gold-shiny);
}
.Text13 {
  color: var(--dl-color-secondary-100);
}
.Text14 {
  color: var(--dl-color-gold-shiny);
}
.Text15 {
  color: var(--dl-color-gold-shiny);
}
.container08 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.text16 {
  color: var(--dl-color-gold-shiny);
  width: 100%;
  font-size: 1.575rem;
  margin-top: 0px;
  text-align: left;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.375;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.container09 {
  width: auto;
  height: auto;
  display: flex;
  z-index: 1;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.image1 {
  width: 62px;
  height: 62px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius1);
}
.image2 {
  width: 62px;
  height: 62px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius1);
}
.container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  z-index: 1;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.image3 {
  width: 62px;
  height: 62px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius1);
}
.image4 {
  width: 62px;
  height: 62px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius1);
}
.rootClassName {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.rootClassName1 {
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.rootClassName2 {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.rootClassName3 {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .container {
    min-width: 300px;
    flex-direction: column;
  }
  .container02 {
    margin-top: 1rem;
    margin-left: 0;
  }
  .text {
    text-align: center;
    margin-left: 0;
  }
}
@media(max-width: 767px) {
  .container {
    flex-direction: flex-direction;
  }
  .container02 {
    align-items: center;
  }
  .text {
    text-align: center;
    margin-left: 0;
  }
  .container03 {
    justify-content: space-between;
  }
  .container08 {
    width: 50%;
    margin-left: 1rem;
    justify-content: center;
  }
  .text16 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .container02 {
    width: 100%;
  }
  .text {
    text-align: center;
    margin-left: 0;
  }
  .container03 {
    justify-content: center;
  }
}
