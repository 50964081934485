:root {
  --dl-color-blue-500: #17c1e8ff;
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-800: #D9D9D9;
  --dl-color-gray-900: #f8f9faff;
  --dl-color-pink-100: #eea9cdff;
  --dl-color-pink-200: #e293d3ff;
  --dl-color-pink-300: #e370abff;
  --dl-color-pink-500: #dd559bff;
  --dl-color-pink-700: #cb0c9fff;
  --dl-size-size-unit: 1rem;
  --dl-color-gold-gold: rgb(255, 185, 72);
  --dl-color-gold-shiny: rgb(255, 240, 199);
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 1rem;
  --dl-color-primary-100: #c172d5ff;
  --dl-color-primary-500: #ac43c7ff;
  --dl-color-primary-700: #9534adff;
  --dl-size-size-halfunit: 0.5rem;
  --dl-color-secondary-100: #d2d6daff;
  --dl-color-secondary-200: #8392abff;
  --dl-color-secondary-300: #808fbfff;
  --dl-color-secondary-400: #67748eff;
  --dl-color-secondary-500: #344767ff;
  --dl-color-secondary-600: #252f40ff;
  --dl-color-secondary-700: #20273fff;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-space-space-sixunits: 6rem;
  --dl-radius-radius-radius1: 1rem;
  --dl-radius-radius-radius5: 0.5rem;
  --dl-space-space-fourunits: 4rem;
  --dl-radius-radius-radius40: 40px;
  --dl-radius-radius-radius75: 0.75rem;
  --dl-size-size-unitandahalf: 1.5rem;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
  --dl-space-space-twelveunits: 12rem;
  --dl-size-size-triplequarterunit: 0.75rem;
  --dl-space-space-unitandahalfunit: 1.5rem;
  --dl-space-space-triplequarterunit: 0.75rem;
}
.teleport-show {
  display: flex !important;
}
.button {
  color: var(--dl-color-secondary-400);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Open Sans;
  border-color: var(--dl-color-secondary-400);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 80%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: disc;
  list-style-position: outside;
}
.list-item {
  color: var(--dl-color-secondary-100);
  display: list-item;
  font-size: large;
}
.textarea {
  color: var(--dl-color-secondary-400);
  cursor: auto;
  padding: 0.5rem;
  font-family: Open Sans;
  border-color: var(--dl-color-secondary-400);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-secondary-400);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Open Sans;
  border-color: var(--dl-color-secondary-400);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.leftSide {
  flex: 0 0 auto;
  width: 50%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 344px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.RoadmapStep {
  width: 50%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.small {
  font-size: 0.875rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.large {
  font-size: 0.875rem;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: -0.025rem;
  text-transform: none;
  text-decoration: none;
}
.medium {
  font-size: 1.125rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.625;
  text-transform: none;
  text-decoration: none;
}
.body {
  font-size: 1rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.625;
  text-transform: none;
  text-decoration: none;
}
.headingOne {
  font-size: 2.25rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}
.headingTwo {
  font-size: 1.875rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.375;
  text-transform: none;
  text-decoration: none;
}
.lead {
  font-size: 1.25rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.625;
  text-transform: none;
  text-decoration: none;
}
.headingThree {
  font-size: 1.25rem;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 1.375;
  text-transform: none;
  text-decoration: none;
}
.label {
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.buttonSmall {
  font-size: 0.75rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.025rem;
  text-transform: uppercase;
}

@media(max-width: 767px) {
  .leftSide {
    width: 80%;
  }
  .list{
    width: 100%;
  }
}




/* Reveal animation */

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}