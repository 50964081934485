.Footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: #131414;
}
.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.image {
  height: 228px;
  margin-bottom: 20px;
  margin-top: 5px;
  z-index: 1;
  position: absolute;
  object-fit: cover;
}
.Line {
  width: 100%;
  height: 2px;
  display: flex;
  background-image: linear-gradient(90deg, rgba(189, 195, 199, 0) 0.00%,rgb(189, 195, 199) 50.00%,rgba(80, 44, 44, 0) 100.00%);
}
.container1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 140px;
  justify-content: space-between;
}
.container2 {
  width: 30%;
  display: flex;
  justify-content: space-around;
}
.navlink {
  margin: 10px;
  transition: 0.3s;
  text-decoration: none;
  color: var(--dl-color-secondary-200);
}
.navlink:hover {
  text-shadow: 0px 0px 10px #fff;
}

.link {
  margin: 10px;
  transition: 0.3s;
  color: var(--dl-color-secondary-200);
}
.link:hover {
  text-shadow: 0px 0px 10px #fff;
}
.container3 {
  width: 30%;
  display: flex;
  justify-content: space-around;
}

.Line1 {
  width: 100%;
  height: 2px;
  display: flex;
  background-image: linear-gradient(90deg, rgba(189, 195, 199, 0) 0.00%,rgb(189, 195, 199) 50.00%,rgba(80, 44, 44, 0) 100.00%);
}
.container4 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 1;
  max-width: 1350;
  justify-content: center;
}
.container5 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  margin-top: 50px;
  align-items: center;
  flex-direction: column;
}
.text {
  color: var(--dl-color-secondary-200);
}
.rootClassName {
  background-image: radial-gradient(circle at center, rgba(183, 80, 174, 0) 0.00%,rgba(183, 80, 174, 0) 100.00%);
}
@media(max-width: 991px) {
  .container2 {
    width: 40%;
  }
  .container3 {
    width: 40%;
  }
}
@media(max-width: 767px) {
  .container {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .image {
    position: relative;
  }
  .container1 {
    margin: 0;
  }
  .container2 {
    width: 100%;
  }
  .container3 {
    width: 100%;
  }
}
